import { gql } from '@apollo/client'
import { DO_CONTACT_INFO_FC_FIELDS } from 'graphql/fragments/DOContactInfoFCFields'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { DO_BENEFICIARY_FIELDS } from 'graphql/fragments/DOBeneficiaryFields'

export const DO_CUSTOMER_FIELDS = gql`
  ${DO_CONTACT_INFO_FC_FIELDS}
  ${DO_CONTACT_NAME_FC_FIELDS}
  ${DO_BENEFICIARY_FIELDS}
  fragment DOCustomerFields on object_DOCustomer {
    id
    badge
    login
    blacklisted
    contactName {
        ...DOContactNameFCFields
    }
    beneficiaries {
        ...DOBeneficiaryFields
    }
    contactInfo{
        ...DOContactInfoFCFields
    }
  }
`