import React, { useEffect, useState } from 'react'
import './Navbar.css'

// components
import Searchbar from './Searchbar'
import PimcoreBtn from './PimcoreBtn'
import Profile from './Profile'

// redirige vers home
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../../store/auth-context'
import SearchbarStoreOptician from './SearchbarStoreOptician'
import { capitalizeFirstLetter } from 'utils/functions'
import { Modal } from 'react-bootstrap'
import ModalErrorMessage from 'components/Modal/ModalErrorMessage'
import axios from 'axios'
const Navbar: React.FC = () => {
  const history = useHistory()
  const authCtx = useContext(AuthContext)
  
  const [env, setEnv] = useState<string>('')
  const [versionDate, setVersionDate] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)

  useEffect(() => {
    //path to version.txt file
    const versionFilePath = '/version.txt';
    const headers = {
      'Content-Type': 'text/plain'
    };
    axios.get(versionFilePath, {headers})
    .then(response => {
      if(response.data){
        const data = response.data
        // parse the version date from the file (yyyyy-mm-dd-hh-mm-ss)
        const match = data.match(/Generated at: (\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2})/);
        if (match && match[1]) {
          const [year, month, day, hour, min] =  match[1].split('-');
          setVersionDate(`(${day}/${month}/${year} ${hour}:${min})`)
        } else {
          setVersionDate(window.location.hostname==='optiqualdev.bor.web.oxv.fr'?'':'Version date not found');
        }
        //search "For environment:" in the file
        const matchEnv = data.match(/For environment: ([^\n]*)/);
        if (matchEnv && matchEnv[1]) {
          setEnv(matchEnv[1]);
        } else {
          setEnv(window.location.hostname==='optiqualdev.bor.web.oxv.fr'?'staging':'Environment not found');
        }
      }else {
        setEnv('Data not found');
      }
    })
    .catch(error => {
      // console.log(error)
      // console.log(window.location.hostname)
      // if "For environment:" is not found => default is 'local' or 'staging'
      switch(window.location.hostname){
        case 'localhost':
          setEnv('local')
          break;
        case 'optiqualdev.bor.web.oxv.fr':
          setEnv('staging')
          break;
        default:
          setEnv('File not found')
      }
    })
  }, []);

  return (
    <div className="Navbar stick">
      <nav className="container-fluid navbar navbar-expand-lg navbar-light bg-white border-bottom stick">
        <div className="container-fluid d-flex justify-content-between flex-nowrap">
          <span className="navbar-brand">
            <img
              className="logo cursor"
              src="/assets/DO-logo.png"
              alt="logo direct optic"
              onClick={() => history.push('/')}
            />
          </span>
          <div className="container hidden-xs">
            <span className="navbar-text">
              Back-office 
              {env && <>&nbsp;- {capitalizeFirstLetter(env)} <span className='small-content'>{versionDate}</span></>}
            </span>
          </div>
          {authCtx.isLoggedIn && (
            <>
              {(!authCtx.user.roles ||
                !(authCtx.user.roles as string[]).includes(
                  authCtx.websiteSettings.roleOpticienMagasin
                )) && <Searchbar />}
              {authCtx.user.roles &&
                (authCtx.user.roles as string[]).includes(
                  authCtx.websiteSettings.roleOpticienMagasin
                ) && <SearchbarStoreOptician />}
              <div className="container-fluid d-flex align-items-center justify-content-lg-end">
                {authCtx.user.pimcoreAccess && <PimcoreBtn />}
                <Profile />
              </div>
            </>
          )}
        </div>
      </nav>

      <div className="ModalError">
        <Modal
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          dialogClassName={
            ' modal-style d-flex justify-content-center align-items-center'
          }
        >
          <Modal.Body>
            <span
              className="btn btn-close position-absolute p-3 top-0 end-0"
              onClick={() => setShowErrorModal(false)}
            ></span>
            <ModalErrorMessage error="Impossible de joindre le serveur" />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Navbar
