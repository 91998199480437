import { constants } from './constants'

//give timestamp get time 00:00:00
export const timestampToFrenchTime = (date: number) => {
  let frFormat = new Intl.DateTimeFormat('fr', {
    timeStyle: 'short',
  })
  let newDate = new Date(date)
  return frFormat.format(newDate)
}

//give date String get age
export const getAge = (date: string | null) => {
  if(date === null)
    return 0
  const today = new Date()
  const birthDate = new Date(date?.replace(/ - /g, '/').replace(/ /g, 'T'))
  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  if (isNaN(age)) 
  {
    console.error(`getAge: format wrong on date [${date}]`)
    return 0
  }
  return age
}

//give dateString get format date YYYY-MM-DD (DATE INPUT FORMAT)
export const getDateInputFormat = (date: string | undefined) => {
  if (date) {
    return date.slice(0, 10)
  }
}

export const getFormattedDate = (date: string | Date | number) => {
  return getFormattedDateTime(date).slice(0, 10)
}
//get date with hours and minutes DD/MM/YYYY HH:mm
export const getFormattedDateWithHM = (date: string | Date | number) => {
  return getFormattedDateTime(date).slice(0, 16)
}

// Takes format YYYY-MM-DD HH:mm:ss and converts it to DD/MM/YYYY HH:mm:ss
export const getFormattedDateTime = (
  date: string | Date | number,
  seconds: boolean = true
) => {
  try {
    if (!(date instanceof Date)) date = new Date(date)
    return date
      .toLocaleDateString(
        'fr-FR',
        seconds
          ? {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }
          : {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }
      )
      .replace(',', '')
  } catch (ex) {
    console.error(ex)
    return ''
  }
}

export const getTokenFromLocalStorage = () => {
  const storedUser = localStorage.getItem('user')
  let token = ''
  if (storedUser !== null) {
    const jsonStoredUser = JSON.parse(storedUser)
    token = jsonStoredUser.token
  }
  return token
}

export const capitalizeFirstLetter = (content: string) => {
  return content.charAt(0).toUpperCase() + content.slice(1)
}

export const checkRetraitParLeClient = (order: orderType): boolean => {
  let addOrder = false
  if (order.workflowState === 'delivery_in_progress') {
    if (
      order.events.filter(
        (event: orderEvent) =>
          event.closureDate === null &&
          event.eventType.key ===
            constants.eventTypeKeys.ORDER_EVENT_TYPE_ARRIVED_AT_STORE
      ).length > 0
    )
      addOrder = true
  }
  return addOrder
}

export const extractPhoneNumberFromContactInfo = (contactInfo: any): string => {
  let phoneNumber = '-'
  if (contactInfo !== null) {
    const mobilePhoneNumber = contactInfo[0].mobilePhoneNumber
    const landlinePhoneNumber = contactInfo[0].landlinePhoneNumber
    if (mobilePhoneNumber && landlinePhoneNumber) {
      phoneNumber = mobilePhoneNumber + ' / ' + landlinePhoneNumber
    } else {
      phoneNumber = mobilePhoneNumber
        ? mobilePhoneNumber
        : landlinePhoneNumber
        ? landlinePhoneNumber
        : '-'
    }
  }
  return phoneNumber
}

export const stringsToJSXs = (arrayOfStrings: string[], t: any) => {
  let aJSX: { [key: string]: JSX.Element } = {}
  arrayOfStrings.forEach((title: any) => {
    aJSX[title] = <>{t(title)}</>
  })

  return aJSX
}

// on veut afficher tous les équipements monture +2 verres regroupés par ordonnance dans le 1e tableau 'Équipement'
// L'ordre: DOOLItemFrameFC puis DOOLItemLensFC oeil droit puis oeil gauche
//trie les items dans l'ordre puis les regroupe par ordonnance
export const sortOrderLinesByPrescriptions = (arrayOfOrderLines: any) => {
  const newOrderLines = {} as any
  arrayOfOrderLines
    ?.filter((orderLine: any) => orderLine.prescription !== null) //filtre les orderLines avec prescription
    .map((orderLine: any) => {
      // classe les item dans l'ordre d'id croissant
      const newOrderLineItem = orderLine.items
        .slice() //the array is frozen in strict mode => need to copy the array before sorting it
        .sort(function (itemA: any, itemB: any) {
          return itemA.id - itemB.id
        })
      //nouvel orderLine avec les items ordonnés
      const newOrderLine = { ...orderLine, items: newOrderLineItem }
      // regroupe les orderLine par prescription
      if (newOrderLines[orderLine.prescription.id]) {
        return (newOrderLines[orderLine.prescription.id] = [
          ...newOrderLines[orderLine.prescription.id],
          newOrderLine,
        ])
      } else {
        return (newOrderLines[orderLine.prescription.id] = [newOrderLine])
      }
    })

  let arrayOfOrderLinesByPrescription = [] as any
  Object.keys(newOrderLines).map((key, index) => {
    return arrayOfOrderLinesByPrescription.push(newOrderLines[key])
  })
  return arrayOfOrderLinesByPrescription
}

// on veut afficher tous les items autres que monture avec correction dans le 2e tableau 'Accessoire' et dans un certain ordre:
// DOOLItemFrameFC
// DOOLItemAccessoryFC
// DOOLItemShippingFC
// DOOLItemVoucherFC
// trie les items dans l'ordre et les fusionne dans une orderLine afin d'être afficher dans le 2e tableau des 'Accessoire'
// TODO : simplifier cette fonction (calculs en back)
export const sortOrderLinesByType = (arrayOfOrderLines: any[]) => {
  const listByType = [] as any // array d'objets orderlines
  const types = [
    'fieldcollection_DOOLItemFrameFC',
    'fieldcollection_DOOLItemLensReplacementFC',
    'fieldcollection_DOOLItemAccessoryFC',
    'fieldcollection_DOOLItemShippingFC',
    'fieldcollection_DOOLItemVoucherFC',
  ]
  //sort items by type
  arrayOfOrderLines
    ?.filter((orderLine: any) => orderLine.prescription === null)
    .map((orderLine: any) => {
      return types.map((type: string, i: number) => {
        if (
          orderLine.items.find(
            (item: any) =>
              item.item && (item.item[0].__typename === 'fieldcollection_DOOLItemFrameFC' ||
              item.item[0].__typename ===
                'fieldcollection_DOOLItemLensReplacementFC')
          )
        ) {
          if (listByType[0] && listByType[0].indexOf(orderLine) === -1) {
            listByType[0] = [...listByType[0], orderLine]
          } else if (!listByType[0]) {
            listByType[0] = [orderLine]
          }
        } 
        else if (
          orderLine.items.find(
            (item: any) =>
              item.item && item.item[0].__typename === 'fieldcollection_DOOLItemAccessoryFC'
          )
        ) {
          if (listByType[1] && listByType[1].indexOf(orderLine) === -1) {
            listByType[1] = [...listByType[1], orderLine]
          } else if (!listByType[1]) {
            listByType[1] = [orderLine]
          }
        } else if (
          orderLine.items.find(
            (item: any) =>
              item.item && item.item[0].__typename === 'fieldcollection_DOOLItemShippingFC'
          )
        ) {
          if (listByType[3] && listByType[3].indexOf(orderLine) === -1) {
            listByType[3] = [...listByType[3], orderLine]
          } else if (!listByType[3]) {
            listByType[3] = [orderLine]
          }
        } else if (
          orderLine.items.find(
            (item: any) =>
              item.item && item.item[0].__typename === 'fieldcollection_DOOLItemVoucherFC'
          )
        ) {
          if (listByType[4] && listByType[4].indexOf(orderLine) === -1) {
            listByType[4] = [...listByType[4], orderLine]
          } else if (!listByType[4]) {
            listByType[4] = [orderLine]
          }
        }
        else {
          if (listByType[2] && listByType[2].indexOf(orderLine) === -1) {
            listByType[2] = [...listByType[2], orderLine]
          } else if (!listByType[2]) {
            listByType[2] = [orderLine]
          }
        }
        return ''
      })
    })

  return listByType
}

export const printOrderIdsPdf = (orderIds: string, callback: () => void) => {
  // See https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch to have application/x-www-form-urlencoded Content-Type
  let formBody = []
  formBody.push(
    encodeURIComponent('token') +
      '=' +
      encodeURIComponent(getTokenFromLocalStorage())
  )
  formBody.push(
    encodeURIComponent('orderIds') + '=' + encodeURIComponent(orderIds)
  )
  const formBodyString = formBody.join('&')

  // See https://stackoverflow.com/a/42274086 for fetch and download pdf
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', // to prevent from having a preflight request OPTIONS (case when body is of type application/json)
    },
    body: formBodyString,
  }
  fetch(
    process.env.REACT_APP_PIMCORE_URL_DOMAIN + '/bo/render_peniche_pdf',
    requestOptions
  )
    .then((response) => {
      if (response.status !== 200) {
        // Checks done here because .catch((err) => {}) does not seem to work
        alert(
          "Erreur lors de la récupération de l'étiquette, veuillez réessayer"
        )
        // NB: no need to call callback() here: will be called in next .then
        return null
      }

      return response.blob()
    })
    .then((blob) => {
      if (!blob || blob.size === 0) {
        // Checks done here because .catch((err) => {}) does not seem to work
        callback()
        return null
      }

      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = 'print_' + orderIds.split(',').join('-') + '.pdf'
      a.target = '_blank'
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again

      callback()
    })
}

export const isVoucherOnTime = (
  dateBegin: string,
  dateEnd: string
): boolean => {
  let now = new Date()
  let beginDate = dateBegin !== null ? new Date(dateBegin) : null
  let endDate = dateEnd !== null ? new Date(dateEnd) : null
  let isVoucherOnTime = false
  if (
    beginDate != null &&
    beginDate.getTime() <= now.getTime() &&
    endDate != null &&
    endDate.getTime() >= now.getTime()
  ) {
    isVoucherOnTime = true
  }
  return isVoucherOnTime
}

export const getLatestTotalAmount = (totalAmountArray: any) => {
  // Upon loading or if there is no amount in history, display default string
  if (!totalAmountArray || !Object.keys(totalAmountArray).length)
    return 'Ajouter un montant'

  // Get the latest total amount by reducing to the one
  // with the highest number obtained from stripped date format
  const latestTotalAmount = totalAmountArray.reduce((prev: any, current: any) =>
    +prev.entryDate.replace(/\D/g, '') > +current.entryDate.replace(/\D/g, '')
      ? prev
      : current
  )
  return latestTotalAmount.totalAmount
    ? latestTotalAmount.totalAmount.value.toFixed(2)
    : 'Ajouter un montant'
}

//give birthdate get prescription validity years
export const getValidityYears = (birthDate: string) => {
  let beneficiaryAge = getAge(birthDate)
  // - 16 ans : ordo valable 1 an.
  // entre 16 et 42 ans : ordo valable 5 ans.
  // plus de 43 ans : ordo valable 3 ans.
  if (beneficiaryAge < 16) {
    return 1
  } else if (beneficiaryAge > 43) {
    return 3
  } else {
    return 5
  }
}
//give validity years get warning message for prescription
export const getPrescriptionWarning = (validityYears: number) => {
  if (validityYears === 1) {
    return "⚠️ valable 1 an pour les clients de moins de 16 ans"
  } else if (validityYears === 3) {
    return "⚠️ valable 3 ans pour les clients de plus de 42 ans"
  } else {
    return "⚠️ valable 5 ans pour les clients âgés entre 16 et 42 ans"
  }
}

//give birthdate of beneficiary and date of prescription return a boolean for isPrescriptionValid
//if give validity year return a boolean of isPrescriptionValid
export const isPrescriptionValid = (
  birthDate: string,
  prescriptionDate: string,
  validityYears?:number
) => {
  return (validityYears? validityYears : getValidityYears(birthDate)) > getAge(prescriptionDate)
}

// convert coliship number(barcode) to parcel number
export const getTrackingNumber = (barcode: string) => {
  let id13Extract = barcode.slice(10, 22)
  let id13key = id13Extract.slice(2, 12)
  let id13Array = id13key.split('').reverse()
  let evenAdd = 0
  let oddAdd = 0

  id13Array.map((id13char: string, i: number) => {
    if (i % 2 === 0) return (evenAdd += +id13char)
    else return (oddAdd += +id13char)
  })

  let total = evenAdd * 3 + oddAdd
  let lastDigit = Math.ceil(total / 10) * 10 - total

  return id13Extract + lastDigit
}

// format eye correction : replace "," by "." and add "+" if value > 0
export const formatPrescriptionValue = (value: any) => {
  if(typeof value ==='string')
    value = value.replace(',','.')
  if(isNaN(value) || !Number(value)) return '+0.00'
  return (
    (Number.parseFloat(value) >= 0 ? '+' : '') +
    Number.parseFloat(value).toFixed(2)
  )
}

export const getCorrection = (eyeCorrection: any) => {
  let sphere = eyeCorrection?.[0].sphere
    ? formatPrescriptionValue(eyeCorrection?.[0].sphere)
    : '+0.00'

  let cylinder = eyeCorrection?.[0].cylinder
    ? ' (' +
      formatPrescriptionValue(eyeCorrection?.[0].cylinder) +
      ' @' +
      eyeCorrection?.[0].axis +
      ')'
    : ' (+0.00 @0)'
  let addition = eyeCorrection?.[0].addition
    ? ' ' + formatPrescriptionValue(eyeCorrection?.[0].addition)
    : ' +0.00'

  return sphere + cylinder + addition
}

export const validateCosiumId = (id: string) => {
  return /^[df]\d+$/i.test(String(id))
}

export const validateNumberInput = (value: string) => {
  const regex = /^\d*$/; // regular expression to match empty string or digits
  if(!value)
    return ''
  //else if last character is a digit 
   else if (regex.test(value.charAt(value.length - 1))) {
    // remove leading zeros and ensure at least one zero is kept
    return value.replace(/^0+/, '').padStart(1, '0');
  } else {
    // remove the last character if it's not a digit
    return value.slice(0, -1);
  }
}

export const validateDecimalInput = (value: string) => {
  const regex = /^(\d*|\d+(\.|,)\d{0,2})$/ // Regular expression to match empty string or decimal with two digits precision max
  if(!value)
    return ''
  //else if last character is part of a decimal number
  if(!regex.test(value))
    return value.slice(0, -1)

  return value.replace(',','.')
}

//genrate password with 4 characters including one letter and one number
export const generatePassword = () => {
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numericChars = '0123456789';
  
  const randomLowercaseChar = lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
  const randomUppercaseChar = uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  const randomNumericChar = numericChars[Math.floor(Math.random() * numericChars.length)];
  const characters =  lowercaseChars + uppercaseChars + numericChars;

  //shuffle all characters
  const allChars = [randomLowercaseChar, randomUppercaseChar, randomNumericChar, characters[Math.floor(Math.random() * characters.length)]];
  for (let i = allChars.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allChars[i], allChars[j]] = [allChars[j], allChars[i]];
  }
  return allChars.join('');
};

// sort array of strings alphabetically
export const sortAlphabetically = (values: string[]) => {
    const sortedValues = [...values]
    return sortedValues.sort((a, b) => a?.localeCompare(b))
}

  //return object with lenses data
  export const getEyewearData = (items?:itemType[]) => {
    let eyewearData = {
      categoryId: '',
      right: {lens: {} as any, price: ''},
      left: {lens: {}  as any, price: ''}
    }
    if(items)
      items.forEach((orderLineItem:any)=>{
        if(orderLineItem.item?.[0]?.eyeSide==='oeil_droit'){
          eyewearData.right.lens = orderLineItem.item[0]?.lens[0]
          eyewearData.right.price = orderLineItem?.unitAmount?.value
        }
        if(orderLineItem.item?.[0]?.eyeSide==='oeil_gauche'){
          eyewearData.left.lens = orderLineItem.item[0]?.lens[0]
          eyewearData.left.price = orderLineItem?.unitAmount?.value
        }
        if (orderLineItem.item?.[0].__typename === 'fieldcollection_DOOLItemFrameFC'){
          eyewearData.categoryId = orderLineItem.item[0].frame[0].category.id
        }else if(orderLineItem.item?.[0].__typename === 'fieldcollection_DOOLItemLensReplacementFC'){
          eyewearData.categoryId = orderLineItem.item[0].lensReplacement[0].category.id
        }
      })
    return eyewearData
  }