import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'
import { ORDER_TOTAL_AMOUNT_FIELDS } from 'graphql/fragments/DOOrderTotalAmountFCFields'

const ADD_TOTAL_AMOUNT_TO_ORDER = gql`
  ${ORDER_TOTAL_AMOUNT_FIELDS}
  ${DO_ORDER_EVENT_FIELDS}
  mutation ADD_TOTAL_AMOUNT_TO_ORDER(
    $orderId: Int!
    $totalAmount: Float!
    $confirmed: Boolean
  ) {
    addTotalAmountToOrder(
      orderId: $orderId
      totalAmount: $totalAmount
      confirmed: $confirmed
    ) {
      success
      output {
        order {
          id
          totalAmountHistory {
            ...DOOrderTotalAmountFCFields
          }
          events {
            ...DOOrderEventFields
          }
        }
        confirmationRequired
        accountingEventAdded
      }
    }
  }
`
export default ADD_TOTAL_AMOUNT_TO_ORDER
