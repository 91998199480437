import './OrderCosiumAllInModal.css'
import {
  Table,
  Button,
  Container,
  Col,
  Row,
  Spinner,
  Form,
} from 'react-bootstrap'
import { constants } from 'utils/constants'
import { useContext, useState, useMemo } from 'react'
import { useMutation, gql } from '@apollo/client'
import { getFormattedDateTime } from 'utils/functions'
import { ToastContext } from 'store/toast-context'
import ADD_TOTAL_AMOUNT_TO_ORDER from 'graphql/mutations/addTotalAmountToOrder'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import GET_ORDER_ACCOUNTING_EVENTS_DATA from 'graphql/queries/getDOOrderAccountingEventsData'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'
import MenuContext from 'store/menu-context'
import { validateCosiumId } from 'utils/functions'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  order: orderType
  isReportedInCosium: boolean
  closeModal?: () => void
  isOnReadyToAssembleModal?: boolean,
  setIsMutationLoading?: React.Dispatch<React.SetStateAction<boolean>>
  calculators: orderCalculatorsType
}

const OrderCosiumAllInModal: React.FC<Props> = ({
  order,
  calculators,
  isReportedInCosium,
  closeModal,
  isOnReadyToAssembleModal,
  setIsMutationLoading
}) => {
  const { toast } = useContext(ToastContext)
  const menuContext = useContext(MenuContext)

  /* Quote id section */
  const ADD_COSIUM_QUOTE_TO_ORDER = gql`
    ${DO_ORDER_EVENT_FIELDS}
    mutation ADD_COSIUM_QUOTE_TO_ORDER(
      $orderId: Int!
      $cosiumId: String!
      $isReportedInCosium: Boolean!
    ) {
      addCosiumQuoteToOrder(
        orderId: $orderId
        cosiumId: $cosiumId
        isReportedInCosium: $isReportedInCosium
      ) {
        success
        output {
          order {
            id
            events {
              ...DOOrderEventFields
            }
          }
        }
      }
    }
  `
  const [addCosiumQuoteToOrder, { loading: quoteLoading }] = useMutation(
    ADD_COSIUM_QUOTE_TO_ORDER, {
      refetchQueries: [
            CQ_GET_ORDER
        ]
    }
  )

  const [newCosiumId, setNewCosiumId] = useState('')
  const isCosiumIdValid = useMemo(
    () => validateCosiumId(newCosiumId),
    [newCosiumId]
  )

  const addNewCosiumQuoteClickHandler = async () => {
    try {
      if (isCosiumIdValid)
        await addCosiumQuoteToOrder({
          variables: {
            orderId: +order.id,
            cosiumId: newCosiumId.toUpperCase(),
            isReportedInCosium: isReportedInCosium,
          },
        })

      // Reset variable
      setNewCosiumId('')
      setIsEditing(false)
    } catch (ex) {
      console.log(ex)
    }
  }

  const {
    ORDER_EVENT_TYPE_TO_REFUND,
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
  } = constants.eventTypeKeys

  // mutation pour ajouter un nouveau montant cosium à la commande
  const [addTotalAmountToOrder, { loading: amountLoading }] = useMutation(
    ADD_TOTAL_AMOUNT_TO_ORDER,
    {
      onCompleted: (data) => {
        if (data.addTotalAmountToOrder.output.confirmationRequired.length > 0) {
          setConfirmationRequiredMessage(
            data.addTotalAmountToOrder.output.confirmationRequired
          )
        } else if (
          isOnReadyToAssembleModal &&
          !data.addTotalAmountToOrder.output.confirmationRequired.length &&
          closeModal
        ) {
          // dans la modale du bouton atelier Kehl prêt à monter si on n'a pas besoin de reconfirmer
          if (+newTotalAmountValue <= +calculators.totalPaidAmount) {
            callCreateOrderEvent()
          } else {
            closeModal()
          }
        } else initAllStates()

        if (data.addTotalAmountToOrder.output.accountingEventAdded === true)
          menuContext.setSideBarNewEventCount(
            ++menuContext.sideBarNewEventCount
          )
      },
    }
  )

  // mutation qui crée l'event prêt à monter et passe la commande en attente de préparation
  const [createDOOrderEvent, { loading: createEventLoading }] = useMutation(
    CM_CREATE_DO_ORDER_EVENT,
    {
      onCompleted: () => {
        if(setIsMutationLoading)
          setIsMutationLoading(false)
        
        toast({
          title: 'Succès :',
          message: 'La commande est en attente de préparation',
        })
        // update order to pick in accordionMenu
        menuContext.setTotalOrderToPick(++menuContext.totalOrderToPick)
        if (closeModal) closeModal()
      },
    }
  )

  const callCreateOrderEvent = async () => {
    try {
      await createDOOrderEvent({
        variables: {
          orderId: +order.id,
          eventTypeKey:
            constants.eventTypeKeys.ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  // fonction qui vérifie les events comptable avant de créer l'event prêt à monter
  const checkWhenSameValue = () => {
    const accountingEvents = order.events.filter(
      (e: orderEvent) =>
        e.closureDate === null &&
        [
          ORDER_EVENT_TYPE_TO_REFUND,
          ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
        ].includes(e.eventType?.key)
    )

    // s'il n'y a pas d'event comptable ouvert on crée l'event prêt à monter
    if (accountingEvents.length === 0) {
      callCreateOrderEvent()
    } else if (
      // s'il n'y a pas d'event comptable must pay extra ouvert on demande confirmation
      accountingEvents.length > 0 &&
      accountingEvents[0].eventType.key !==
        ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA
    ) {
      setShowTotalAmountConfirmation(true)
    } else {
      // s'il y a un supplément on ne crée pas d'event prêt à monter et affiche un message d'erreur
      setErrorMessage(
        `Cette commande à une demande de supplément de ${Math.abs(
          +newTotalAmountValue - +calculators.totalPaidAmount
        ).toFixed(2)}${
          constants.currency.EURO
        }. Impossible de passer la commande en fabrication.`
      )
    }
  }

  // Pipeline walkthrough
  // parse: clone totalAmountList array
  // sort: sort it by descending order on entryDate property
  // map: add index property to each object, which is the substrative reverse of totalAmountList index values

  const totalAmounts =
    order.totalAmountHistory !== null
      ? JSON.parse(JSON.stringify(order.totalAmountHistory))
          .sort((a: any, b: any) =>
            +a.entryDate.replace(/\D/g, '') < +b.entryDate.replace(/\D/g, '')
              ? 1
              : -1
          )
          .map((total: any, index: number) => {
            total.index = order.totalAmountHistory.length - index
            return total
          })
      : []

  const [showTotalAmountHistory, setShowTotalAmountHistory] = useState(
    isOnReadyToAssembleModal || !totalAmounts.length ? false : true
  )
  const [showTotalAmountConfirmation, setShowTotalAmountConfirmation] = useState(false)
  const [isSameTotalAmount, setIsSameTotalAmount] = useState(false)
  // Editing mode should be on if no cosium ID was found in order
  const [isEditing, setIsEditing] = useState(!calculators.cosiumId.length)
  // If in ready to assemble mode, totalAmounts is not empty and newTotalAmountValue equals the last entry value
  const [newTotalAmountValue, setNewTotalAmountValue] = useState(
    isOnReadyToAssembleModal && totalAmounts.length ? totalAmounts[0].totalAmount.value : ''
  )
  const [confirmationRequiredMessage, setConfirmationRequiredMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const initAllStates = () => {
    if (isOnReadyToAssembleModal) {
      setShowTotalAmountHistory(false)
    } else {
      setShowTotalAmountHistory(true)
    }
    setShowTotalAmountConfirmation(false)
    setIsSameTotalAmount(false)
    setNewTotalAmountValue('')
    setConfirmationRequiredMessage('')
    setErrorMessage('')
  }

  const addNewTotalAmountClickHandler = async (isConfirmed: boolean) => {
    try {
      if(setIsMutationLoading)
        setIsMutationLoading(true)

      await addTotalAmountToOrder({
        variables: {
          orderId: +order.id,
          totalAmount: +newTotalAmountValue,
          confirmed: isConfirmed,
        },
        refetchQueries: [GET_ORDER_ACCOUNTING_EVENTS_DATA],
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  // Button shouldn't be disabled if in ready to assemble mode
  const isBtnDisabled =
    (!newTotalAmountValue.length && !isOnReadyToAssembleModal) ||
    amountLoading ||
    createEventLoading ||
    errorMessage.length > 0

  let confirmationMessage = ''
  if (+newTotalAmountValue === +calculators.totalPaidAmount) {
    if (+newTotalAmountValue === 0)
      confirmationMessage =
        order.workflowState === 'created'
          ? "Cette action va ajouter un statut d'attente de validation rien à payer."
          : order.workflowState === 'awaiting_payment'
          ? 'Attention aucun paiement n’a eu lieu et vous allez mettre le montant total à 0, ce qui va engendrer la validation de la commande. Êtes-vous sûr(e) de valider cette commande pour traitement ?'
          : isOnReadyToAssembleModal
          ? 'Vous allez mettre le montant total à 0 et passer la commande en attente de préparation. Êtes-vous sûr(e) de passer la commande en attente de préparation ?'
          : 'Vous allez mettre le montant total à 0. Êtes-vous sûr(e) de vouloir continuer ?'
    else
      confirmationMessage = `Êtes-vous sûr de vouloir confirmer le montant par ${(+newTotalAmountValue).toFixed(
        2
      )} ${constants.currency.EURO} ? ${
        isOnReadyToAssembleModal
          ? 'La commande passera en attente de préparation.'
          : ''
      }`
  } else {
    if (parseFloat(calculators.totalPaidAmount) === 0.0) {
      confirmationMessage = `Cette action va ajouter un statut d'attente de paiement par CB de ${parseFloat(
        newTotalAmountValue
      ).toFixed(2)} ${
        constants.currency.EURO
      } ou modifier son montant s'il y en a déjà un dans la commande.`
    } else if (+newTotalAmountValue > +calculators.totalPaidAmount)
      confirmationMessage = `Cette action va envoyer une demande de supplément de
                                ${Math.abs(
                                  +newTotalAmountValue - +calculators.totalPaidAmount
                                ).toFixed(2)} ${
        constants.currency.EURO
      } au client ${
        isOnReadyToAssembleModal
          ? ' et ne passera pas la commande en attente de préparation.'
          : ''
      }`
    else
      confirmationMessage = `Cette action va faire une demande de remboursement de ${Math.abs(
        +newTotalAmountValue - +calculators.totalPaidAmount
      ).toFixed(2)} ${constants.currency.EURO} à la comptabilité ${
        isOnReadyToAssembleModal
          ? ' et passera la commande en attente de préparation.'
          : ''
      }`
  }

  const confirmationBtnText =
    isOnReadyToAssembleModal && +newTotalAmountValue > +calculators.totalPaidAmount
      ? `Faire la demande de supplément`
      : isOnReadyToAssembleModal &&
        +newTotalAmountValue < +calculators.totalPaidAmount
      ? 'Continuer'
      : 'Confirmer'

  const changeHandler = (e: any) => {
    if (
      totalAmounts.length &&
      e.target.value - totalAmounts[0].totalAmount.value === 0
    ) {
      setNewTotalAmountValue(e.target.value)
      setIsSameTotalAmount(true)
      e.target.value = newTotalAmountValue
    } else {
      setIsSameTotalAmount(false)
      if (/^(\d*|\d+\.\d{0,2})$/.test(e.target.value))
        setNewTotalAmountValue(e.target.value)
      else e.target.value = newTotalAmountValue
    }
  }
  const clickConfirmHandler = () => {
    if (isSameTotalAmount && isOnReadyToAssembleModal) {
      callCreateOrderEvent()
    } else {
      addNewTotalAmountClickHandler(confirmationRequiredMessage.length > 0)
    }
  }
  const clickSaveHandler = () => {
    // si même montant et dans la modale du bouton atelier Kehl prêt à monter
    if (isSameTotalAmount && isOnReadyToAssembleModal) {
      setShowTotalAmountConfirmation(false)

      //on vérifie les events comptable avant de créer l'event prêt à monter
      checkWhenSameValue()
    } else {
      setShowTotalAmountConfirmation(true)
    }
  }
  const clickCancelSaveHandler = () => {
    if (newTotalAmountValue && !isOnReadyToAssembleModal) {
      initAllStates()
    } else if (isOnReadyToAssembleModal && closeModal) {
      closeModal()
    }
    if (
      !closeModal ||
      totalAmounts.length ||
      (newTotalAmountValue && isOnReadyToAssembleModal)
    ) {
      initAllStates()
    } else {
      closeModal()
    }
  }

  return (
    <>
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Col>
            <h4>
              <strong>Information Devis Cosium</strong>
            </h4>
            <Container className="mt-4" fluid>
              <Row className="align-items-center">
                <Col xs={3}>
                  <span>N° actuel :</span>
                </Col>
                <Col>{calculators.cosiumId.length ? calculators.cosiumId : 'Aucun'}</Col>
                <Col>
                  {!isEditing && (
                    <Button
                      data-cy="modifyCosiumQuote"
                      className="m-2 btn btn-primary w-100"
                      onClick={() => setIsEditing(true)}
                    >
                      Modifier
                    </Button>
                  )}
                </Col>
              </Row>
              {isEditing && (
                <Row className="align-items-center">
                  <Col xs={8}>
                    <Form.Control
                      className="w-100"
                      name="newCosiumId"
                      type="text"
                      value={newCosiumId}
                      onChange={(e) => {
                        setNewCosiumId(e.target.value)
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      data-cy="modifyCosiumQuote"
                      className="m-2 btn btn-primary w-100"
                      disabled={!isCosiumIdValid || quoteLoading}
                      onClick={() => {
                        addNewCosiumQuoteClickHandler()
                      }}
                    >
                      {quoteLoading && (
                        <Spinner
                          variant="light"
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Valider
                    </Button>
                  </Col>
                </Row>
              )}
              {!isCosiumIdValid && newCosiumId && (
                <Row>
                  <div className="text-danger mt-2">
                    Votre saisie ne correspond pas à un n° de devis Cosium. Un n° de devis Cosium commence par la lettre “D” ou “F” suivie de caractères numériques. 
                  </div>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
        <Row className="align-items-center border-top pt-3">
          <Col>
            {/* 1ère modale du bouton montant cosium */}
            <div className={showTotalAmountHistory ? 'fadeIn' : 'fadeOut'}>
              {showTotalAmountHistory && (
                <>
                  <div className="mt-1 text-center">
                    <Button onClick={() => setShowTotalAmountHistory(false)}>
                      Nouveau montant
                    </Button>
                  </div>
                  <div className="mt-4">
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Date</th>
                          <th scope="col">
                            Montant ({constants.currency.EURO})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalAmounts.map((amountLine: any, index: Number) => {
                          return (
                            <tr key={index.toString()} className="hover-green">
                              <td>{amountLine.index}</td>
                              <td>
                                {getFormattedDateTime(amountLine.entryDate)}
                              </td>
                              <td>
                                <strong>
                                  {amountLine.totalAmount.value.toFixed(2)}
                                </strong>{' '}
                                {index === 0 ? '(Actuel)' : ''}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </div>
            <div className={!showTotalAmountHistory ? 'fadeIn' : 'fadeOut'}>
              {!showTotalAmountHistory && (
                <>
                  {/* 2ème modale avec les confirmations */}
                  <Container className="mt-4" fluid>
                    <Form.Group controlId="cosiumAmount">
                        <Row className="align-items-center mb-1">
                          <Col xs={5}>
                            <span>
                              <b>Balance paiements ({constants.currency.EURO})</b>
                            </span>
                          </Col>
                          <Col>
                            {parseFloat(calculators.totalPaidAmount).toFixed(2)}
                          </Col>
                        </Row>
                      {/* cas du bouton montant cosium */}
                      {!isOnReadyToAssembleModal && (
                        <Row className="align-items-center mb-1">
                          <Col xs={5}>
                            <span>
                              <b>Montant cosium ({constants.currency.EURO})</b>
                            </span>
                          </Col>
                          <Col>
                            {totalAmounts.length
                              ? totalAmounts[0].totalAmount.value.toFixed(2)
                              : 'Aucun'}
                          </Col>
                        </Row>
                      )}
                      <Row
                        className={
                          isOnReadyToAssembleModal
                            ? 'justify-content-md-center'
                            : 'align-items-center'
                        }
                      >
                        {!isOnReadyToAssembleModal ? (
                          <Col xs={5}>
                            <span>
                              {/* cas du bouton montant cosium */}

                              <b>Nouveau montant ({constants.currency.EURO})</b>
                            </span>
                          </Col>
                        ) : (
                          <Form.Label>
                            {/* cas du bouton montant atelier Kehl */}
                            <h4>
                              <b>
                                Veuillez vérifier le montant du devis Cosium (€)
                                :
                              </b>
                            </h4>
                          </Form.Label>
                        )}
                        <Col xs={5}>
                          <Form.Control
                            type="text"
                            disabled={showTotalAmountConfirmation}
                            value={newTotalAmountValue}
                            onChange={(e) => {
                              changeHandler(e)
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Container>
                  <div
                    className={
                      !showTotalAmountConfirmation ? 'fadeIn' : 'fadeOut'
                    }
                  >
                    {!showTotalAmountConfirmation && (
                      <>
                        <div
                          className="mt-2 mb-4 text-center small"
                          style={{ height: '20px', color: 'red' }}
                        >
                          {/* message d'erreur si montants égaux */}
                          {errorMessage}
                        </div>

                        <div className="mt-2 mb-4 text-center">
                          Ce montant doit correspondre au montant dans Cosium
                        </div>

                        <div className="mt-3 text-center">
                          <Button
                            data-cy="saveCosiumAmount"
                            className="m-2 btn btn-primary"
                            disabled={isBtnDisabled}
                            onClick={() => {
                              clickSaveHandler()
                            }}
                          >
                            {(amountLoading || createEventLoading) && (
                              <Spinner
                                variant="light"
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Enregistrer
                          </Button>
                          <Button
                            variant="outline-primary bg-white text-primary"
                            className="m-2"
                            onClick={() => clickCancelSaveHandler()}
                          >
                            Annuler
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      showTotalAmountConfirmation ? 'fadeIn' : 'fadeOut'
                    }
                  >
                    {showTotalAmountConfirmation && (
                      <>
                        <div className="mt-4 mb-4 text-center">
                          {!!confirmationRequiredMessage.length && (
                            <>{confirmationRequiredMessage} </>
                          )}
                          {!confirmationRequiredMessage.length && (
                            <>{confirmationMessage}</>
                          )}
                        </div>
                        <div className="mt-3 text-center">
                          <Button
                            data-cy="confirmCosiumAmount"
                            variant={
                              confirmationRequiredMessage.length
                                ? 'success'
                                : ''
                            }
                            className="m-2 btn-primary"
                            disabled={isBtnDisabled}
                            onClick={() => clickConfirmHandler()}
                          >
                            {(amountLoading || createEventLoading) && (
                              <Spinner
                                variant="light"
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {confirmationBtnText}
                          </Button>

                          <Button
                            variant="outline-primary bg-white text-primary"
                            className="m-2"
                            onClick={() => {
                              setConfirmationRequiredMessage('')
                              setShowTotalAmountConfirmation(false)
                            }}
                          >
                            Annuler
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default OrderCosiumAllInModal
